import { graphql } from 'gatsby';
import React from 'react';
import Helmet from 'react-helmet';
import PostListing from '../components/PostListing';
import Layout from '../components/Layout';
import PropTypes from '../PropTypes';

const propTypes = {
  data: PropTypes.shape({
    posts: PropTypes.object.isRequired
  }).isRequired,
  pageContext: PropTypes.shape({
    tag: PropTypes.any.isRequired
  }).isRequired,
  location: PropTypes.location.isRequired
};

function TagPage({ location, data, pageContext }) {
  const { tag } = pageContext;
  const tagName = tag.node.data.name;
  const postEdges = data.posts.edges;

  return (
    <Layout location={location}>
      <div className="element page_tag">#{tagName}</div>
      <Helmet title={`Записи с тегом ${tagName}`} />
      <PostListing postEdges={postEdges} />
    </Layout>
  );
}

TagPage.propTypes = propTypes;

export default TagPage;

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query TagPage($tagUID: String) {
    posts: allPrismicPost(
      limit: 1000
      sort: { fields: [data___date], order: DESC }
      filter: { data: { tags: { elemMatch: { tag: { uid: { eq: $tagUID } } } } } }
    ) {
      edges {
        node {
          slug: uid
          frontmatter: data {
            title {
              text
            }
            description
            date
            category {
              document {
                ... on PrismicCategory {
                  data {
                    name
                  }
                }
              }
            }
            cover {
              localFile {
                childImageSharp {
                  sizes(maxWidth: 800, quality: 90, toFormat: JPG) {
                    ...GatsbyImageSharpSizes_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
